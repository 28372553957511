<template>
  <dicom-pacs-import-modal
    v-if="!is3rdPartyUrl"
    badge-color="black"
    :disabled="isDisabled"
    icon="mdi-plus"
    :dark="darkMode"
    icon-color="accent"
    :options="options"
    @pacs-retrieve-complete="refreshDashboard"
    @pacs-retrieve-open="openViewer"
  >
    <template v-slot="{ minimizedSeries, on, uploading }">
      <v-list-item :disabled="isDisabled" v-on="on" id="IM2">
        <v-list-item-content>
          <v-list-item-title @click="$emit('close-import-exams-menu')">
            <v-icon class="mr-2">mdi-download-network</v-icon>
            <span>PACS</span>
            <v-progress-circular
              v-if="minimizedSeries"
              class="ml-2"
              color="accent"
              :indeterminate="uploading"
              :size="24"
              :value="100"
              :width="3"
            >
              {{ minimizedSeries }}
            </v-progress-circular>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </dicom-pacs-import-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { echo, query, retrieve, getModalities } from "@/js/api.pacs";
import { viewerLayouts } from "@/js/preferences";

export default {
  name: "ImportPacs",
  data() {
    return {
      options: {
        api: { echo, query, retrieve },
        modalities: { results: null, error: null }
      },
      is3rdPartyUrl: process.env.APP_CUSTOMER.getExternal3rdPartyURL
    };
  },
  props: {
    dark: { default: false, type: Boolean }
  },
  computed: {
    ...mapGetters("auth", ["isAuth"]),
    ...mapGetters(["darkMode"]),
    isDisabled() {
      return this.$isDemoUser || !this.isAuth;
    }
  },
  async mounted() {
    if (this.isDisabled) {
      return;
    }

    try {
      this.options.modalities.results = (await getModalities()) || [];
    } catch (error) {
      console.error(error);
      this.options.modalities.error = error;
    }
  },
  methods: {
    refreshDashboard() {
      this.$root.$emit("dashboard-refresh");
    },
    openViewer(studies) {
      if (this.isDisabled) {
        return;
      }

      // initialize series:
      // set as not downloaded and add empty masks_info
      studies = studies.map(study => {
        study.series.forEach(s => {
          delete s.study;
          s.upload_completed = true; // once retrieved, studies are saved on server too
          s.download_completed = false; // need to download images
          s.masks_info = [];
        });
        return study;
      });

      this.$store.commit("viewer/setServer", true);
      this.$store.commit("viewer/initData", studies);

      if (this.$route.name !== "viewer") {
        this.$router.push({ name: "viewer" });
      } else {
        // already in viewer
        this.$store.commit("viewer/updateLayout", viewerLayouts.stack[0]);
      }
    }
  }
};
</script>
