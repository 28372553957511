// Default preferences
import metadata from "./utils/dicomMetadata";

// Available canvas types
const _CanvasTypes = Object.freeze({ stack: 0, mpr: 1, vr: 2 });

// Viewer layouts
const defaultLayoutOptions = {
  showActiveViewportOnly: false,
  showMasks: false,
  showSendToPacs: true
};

const defaultLayoutTools = {
  interactions: true,
  measurements: true,
  colorMaps: true,
  viewportActions: true,
  ai: false,
  options: true
};

// !!! names should be unique
const _layouts = {
  stack: [
    {
      name: "single",
      icon: "mdi-square",
      panes: [{ type: _CanvasTypes.stack }],
      options: defaultLayoutOptions,
      tools: defaultLayoutTools
    },
    // 1x2
    {
      name: "1x2",
      icon: "mdi-view-agenda",
      iconRotation: "90",
      panes: [{ type: _CanvasTypes.stack }, { type: _CanvasTypes.stack }],
      options: defaultLayoutOptions,
      tools: defaultLayoutTools
    },
    // 2x1
    {
      name: "2x1",
      icon: "mdi-view-stream",
      horizontal: true,
      panes: [{ type: _CanvasTypes.stack }, { type: _CanvasTypes.stack }],
      options: defaultLayoutOptions,
      tools: defaultLayoutTools
    },
    // 1x3
    {
      name: "1x3",
      icon: "mdi-view-column",
      panes: [
        { type: _CanvasTypes.stack },
        { type: _CanvasTypes.stack },
        { type: _CanvasTypes.stack }
      ],
      options: defaultLayoutOptions,
      tools: defaultLayoutTools
    },
    // 3x1
    {
      name: "3x1",
      icon: "mdi-view-column",
      iconRotation: "90",
      horizontal: true,
      panes: [
        { type: _CanvasTypes.stack },
        { type: _CanvasTypes.stack },
        { type: _CanvasTypes.stack }
      ],
      options: defaultLayoutOptions,
      tools: defaultLayoutTools
    },
    // (2x1)x1
    {
      name: "(2x1)x1",
      icon: "mdi-view-compact",
      iconRotation: "90",
      panes: [
        {
          horizontal: true,
          panes: [{ type: _CanvasTypes.stack }, { type: _CanvasTypes.stack }]
        },
        { panes: [{ type: _CanvasTypes.stack }] }
      ],
      options: defaultLayoutOptions,
      tools: defaultLayoutTools
    },
    // 2x2
    {
      name: "2x2",
      horizontal: true,
      icon: "mdi-view-grid",
      panes: [
        { panes: [{ type: _CanvasTypes.stack }, { type: _CanvasTypes.stack }] },
        { panes: [{ type: _CanvasTypes.stack }, { type: _CanvasTypes.stack }] }
      ],
      options: defaultLayoutOptions,
      tools: defaultLayoutTools
    }
  ],
  // CURRENT LIMITS:
  // - stack should already be parsed
  // - only one series opened in VR and/or MPR components at a time (eg: 1VR, 1MPR, 1STACK+1VR+1MP, ...)
  advanced: [
    {
      name: "mpr",
      panes: [{ type: _CanvasTypes.mpr }],
      options: {
        ...defaultLayoutOptions,
        showActiveViewportOnly: true
      },
      tools: {
        ...defaultLayoutTools,
        measurements: false,
        colorMaps: false,
        viewportActions: false
      }
    },
    {
      name: "vr",
      panes: [{ type: _CanvasTypes.vr }],
      options: {
        ...defaultLayoutOptions,
        showActiveViewportOnly: true
      },
      tools: {
        ...defaultLayoutTools,
        measurements: false,
        colorMaps: false,
        viewportActions: false
      }
    },
    {
      name: "editing",
      panes: [
        { type: _CanvasTypes.stack },
        {
          type: _CanvasTypes.vr,
          props: { enableSurfaces: true, enableVR: false }
        }
      ],
      options: {
        ...defaultLayoutOptions,
        showActiveViewportOnly: true,
        showMasks: true
      },
      tools: { ...defaultLayoutTools, ai: true }
    }
  ]
};

const _layoutsUI = {
  advanced: {
    mpr: {
      activeIcon: "mdi-cube",
      icon: "mdi-cube-outline"
    },
    vr: {
      icon: "mdi-ghost-outline",
      activeIcon: "mdi-ghost"
    },
    editing: {
      icon: "mdi-transition-masked",
      activeIcon: "mdi-transition-masked"
    }
  }
};

// Schema to parsed keys map
const _schemaToStackMap = {
  // patient
  patient: {
    id: metadata.PatientName,
    birth_date: metadata.PatientBirthDate,
    name: metadata.PatientName,
    gender: metadata.PatientSex,
    patient_id: metadata.PatientID
  },
  // study
  study: {
    id: metadata.StudyInstanceUID,
    accession_number: metadata.AccessionNumber,
    study_date: metadata.StudyDate,
    study_id: metadata.StudyInstanceUID,
    study_description: metadata.StudyDescription
  },
  // series
  series: {
    id: "larvitarSeriesInstanceUID",
    study_date: metadata.SeriesDate,
    bits: metadata.BitsAllocated,
    cols: metadata.Cols,
    modality: metadata.Modality,
    multiframe: "isMultiframe",
    number_of_slices: "larvitarNumberOfSlices",
    number_of_temporal_positions: metadata.NumberOfTemporalPositions,
    pixel_spacing: metadata.PixelSpacing,
    repr: metadata.PixelRepresentation,
    rows: metadata.Rows,
    series_id: "larvitarSeriesInstanceUID",
    seriesInstanceUIDs: "seriesInstanceUIDs",
    series_description: metadata.SeriesDescription,
    thickness: metadata.SliceThickness,
    anonymized: "anonymized",
    to_anonymize: "toAnonymize"
  }
};

// Dashboard table headers by patients
const _dashboardTableHeadersPatients = [
  // { sortable: false, text: "", value: "data-table-expand" },
  {
    sortable: true,
    value: "custom_tag",
    text: process.env.APP_CUSTOMER.customTagPatientsHeader,
    mandatory: true,
    abbreviation: "CTAG"
  },
  { sortable: true, value: "name", mandatory: true, abbreviation: "LN" },
  {
    sortable: true,
    text: "Patient ID",
    value: "patient_id",
    mandatory: false,
    hidden: false,
    abbreviation: "ID"
  },
  { sortable: true, value: "gender", hidden: false, abbreviation: "GENDER" },
  { sortable: true, value: "birth_date", hidden: false, abbreviation: "BIRTH" },
  {
    sortable: true,
    text: "Upload date",
    value: "updated_at",
    hidden: false,
    abbreviation: "UPDAT"
  },
  { sortable: true, value: "anonymized", hidden: true, abbreviation: "ANON" },
  {
    sortable: false,
    value: "has_patient_consent",
    hidden: true,
    abbreviation: "CONS"
  },
  { sortable: false, value: "studies", hidden: false, abbreviation: "NUMSTD" },
  {
    sortable: true,
    text: "modality",
    value: "modalities",
    hidden: false,
    abbreviation: "MOD"
  },
  {
    sortable: false,
    value: "running_async_tasks",
    hidden: false,
    abbreviation: "NJOBS"
  },
  {
    sortable: true,
    text: "owner",
    value: "owner",
    hidden: false,
    abbreviation: "OWN"
  },
  { sortable: false, text: " ", value: "actions", mandatory: true }
];

const _dashboardTableHeadersStudies = [
  // { sortable: false, text: "", value: "data-table-expand" },
  {
    sortable: true,
    value: "custom_tag",
    text: process.env.APP_CUSTOMER.customTagStudiesHeader,
    mandatory: true,
    abbreviation: "CTAG"
  },
  { sortable: true, value: "patient", mandatory: true, abbreviation: "PAT" },
  {
    sortable: false,
    text: " ",
    value: "number_of_masks",
    hidden: true,
    abbreviation: "NMASKS"
  },
  // { sortable: true, value: "study_id" },
  {
    sortable: true,
    value: "accession_number",
    mandatory: true,
    abbreviation: "ACCESSION N°"
  },
  {
    sortable: true,
    value: "study_description",
    hidden: false,
    abbreviation: "DESC"
  },
  {
    sortable: true,
    value: "study_date",
    hidden: false,
    abbreviation: "STDATE"
  },
  {
    sortable: true,
    text: "upload date",
    value: "updated_at",
    hidden: false,
    abbreviation: "UPLD"
  },

  // TODO add this in db
  // { sortable: true, value: "series", hidden: false, abbreviation: "NSERIES" },
  {
    sortable: true,
    text: "# Instances",
    value: "number_of_files",
    hidden: false,
    abbreviation: "NFILES"
  },
  {
    sortable: true,
    text: "modality",
    value: "modalities",
    hidden: false,
    abbreviation: "MOD"
  },
  {
    sortable: false,
    value: "running_async_tasks",
    hidden: false,
    abbreviation: "NJOBS"
  },
  {
    sortable: true,
    text: "surfaces",
    value: "number_of_surfaces",
    hidden: true,
    abbreviation: "NSRFCS"
  },
  {
    sortable: true,
    text: "owner",
    value: "owner",
    hidden: false,
    abbreviation: "OWN"
  },
  { sortable: false, text: " ", value: "actions", mandatory: true }
];

// TODO use for series fields to show in dashboard
const _dashboardTableHeadersSeries = [
  { sortable: false, text: " ", value: "thumbnail" },
  // { sortable: true,  value: "series_id" },
  { sortable: true, value: "series_description" },
  { sortable: true, value: "study_date" },
  { sortable: true, value: "modality" },
  { sortable: false, value: "size", keys: ["rows", "cols"] },
  { sortable: true, value: "number_of_slices" },
  { sortable: true, value: "thickness" }
];

// Dicom import table headers
const _dicomImportTableHeaders = [
  { sortable: false, text: "", value: "preview" },
  { sortable: false, text: "anonymize", value: "anonymized" },
  {
    sortable: false,
    keys: [...new Set(Object.values(_schemaToStackMap.patient))],
    text: "patient",
    value: "patient"
  },
  ...[
    ...new Set(
      _dashboardTableHeadersSeries.filter(({ value }) => value !== "thumbnail")
    )
  ].map(obj => {
    if (obj.keys) {
      return {
        cellClass: `cell-${obj.value}`,
        keys: obj.keys.map(v => _schemaToStackMap.series[v]),
        slot: true,
        sortable: false,
        text: obj.value,
        value: obj.value
      };
    } else {
      return {
        cellClass: `cell-${_schemaToStackMap.series[obj.value]}`,
        sortable: true,
        text: `metadata-${_schemaToStackMap.series[obj.value]}`,
        value: _schemaToStackMap.series[obj.value]
      };
    }
  })
];

// Viewport static information
const _viewportStaticInfo = {
  // TODO patient/study not shown
  patient: ["name", "birth_date", "gender"],
  study: ["study_description", "study_id", "accession_number"],
  series: ["series_description", "series_id", "modality", "study_date"]
};

// Available mouse masks and keyboard events
// const MouseMasks = Object.freeze({ left: 1, right: 2 }); // not used, always 1 by default
const ToolsHandlers = Object.freeze({
  ctrlRight: { key: "ctrl", mouse: "right" },
  shiftRight: { key: "shift", mouse: "right" },
  right: { key: "default", mouse: "right" },
  left: { key: "default", mouse: "left" }
});

// Viewer tools
// - defaultActive = activated by default on init app, not disabled for the specified options
// TODO TOOL slider
const _viewerInteractionsTools = [
  {
    name: "Wwwc",
    defaultActive: true,
    icon: "mdi-contrast-circle",
    mixins: ["enabledOrDisabledBinaryTool"],
    options: {
      handlers: [ToolsHandlers.left]
    }
    // sync: "wwwcSynchronizer" // TODO TOOL activate/deactivate sync option (need larvitar function)
  },
  {
    name: "WwwcRegion",
    action: "WWwc from Region",
    options: {
      mouseButtonMask: 1,
      supportedInteractionTypes: ["Mouse", "Touch"]
    },
    mixins: ["enabledOrDisabledBinaryTool"],
    icon: "mdi-crop-square"
  },
  {
    name: "WwwcRemoveRegion",
    action: " ",
    mixins: ["enabledOrDisabledBinaryTool"],
    options: {
      mouseButtonMask: 1,
      supportedInteractionTypes: ["Mouse", "Touch"]
    },
    icon: "mdi-square"
  },
  {
    name: "Zoom",
    icon: "mdi-magnify",
    mixins: ["enabledOrDisabledBinaryTool"],
    options: {
      handlers: [ToolsHandlers.right]
    }
  },
  {
    name: "Pan",
    icon: "mdi-arrow-all",
    mixins: ["enabledOrDisabledBinaryTool"],
    options: {
      handlers: [ToolsHandlers.ctrlRight, ToolsHandlers.shiftRight]
    }
  },
  {
    name: "Rotate",
    icon: "mdi-rotate-left",
    mixins: ["enabledOrDisabledBinaryTool"]
  },
  {
    name: "StackScroll",
    icon: "mdi-buffer",
    configuration: {
      loop: false, // default false
      allowSkipping: true // default true
    },
    options: {
      mouseButtonMask: 1,
      deltaY: 0 // default 0
    },
    cleanable: false,
    defaultActive: false
  },
  {
    name: "Crosshair",
    icon: "mdi-crosshairs",
    mixins: ["enabledOrDisabledBinaryTool"]
  },
  {
    name: "Crop",
    icon: "mdi-crop"
  },
  // !!! visible: false tools at the end of the list!
  {
    name: "CustomMouseWheelScroll",
    defaultActive: true,
    // icon: "mdi-layers-triple",
    mixins: ["enabledOrDisabledBinaryTool"],
    configuration: {
      currentMode: "stack"
    },
    options: {
      configuration: {
        currentMode: "stack"
      },
      currentMode: "stack"
    },
    visible: false
  },

  {
    name: "ScaleOverlay",
    visible: false
  },
  {
    name: "OrientationMarkers",
    visible: false
  },
  {
    name: "Brush",
    visible: false
  }
];

// TODO TOOL remove all, show/hide all
const _viewerMeasurementsTools = [
  { icon: "mdi-vector-line", name: "Length" },
  { icon: "mdi-vector-polyline", name: "Angle" },
  { icon: "mdi-vector-rectangle", name: "RectangleRoi", dropdown: true },
  { icon: "mdi-vector-ellipse", name: "EllipticalRoi", dropdown: true },
  { icon: "mdi-vector-polygon", name: "FreehandRoi", dropdown: true },
  { icon: "mdi-diameter-variant", name: "Bidirectional", dropdown: true }, // diameter
  { icon: "mdi-crosshairs-gps", name: "Probe", dropdown: true },
  {
    icon: "mdi-crosshairs",
    name: "DragProbe",
    dropdown: true
  },
  {
    icon: "mdi-magnify",
    name: "BorderMagnify",
    dropdown: true
  },
  {
    icon: "mdi-format-text-rotation-none",
    name: "ArrowAnnotate",
    dropdown: true
  },
  {
    icon: "mdi-numeric-1-box",
    name: "TextMarker",
    dropdown: true
  },
  { icon: "mdi-eraser", name: "Eraser", dropdown: true },
  { icon: "mdi-crop-landscape", name: "RectangleRoiOverlay", dropdown: true },
  { icon: "mdi-chart-line", name: "LengthPlot", dropdown: true }
];

const _customerSpecificMeasurementTools =
  process.env.APP_CUSTOMER.customToolsProps || [];

const _viewerViewportActions = [
  { name: "reset", action: "reset-viewport", icon: "mdi-backup-restore" },
  {
    name: "FlipHorizontal",
    action: "flip-horizontal",
    dropdown: true,
    icon: "mdi-flip-horizontal"
  },
  {
    name: "FlipVertical",
    action: "flip-vertical",
    dropdown: true,
    icon: "mdi-flip-vertical"
  },
  {
    name: "Invert",
    action: "invert",
    dropdown: true,
    icon: "mdi-invert-colors"
  },
  {
    name: "export",
    action: "export-viewport",
    dropdown: true,
    icon: "mdi-export"
  },
  {
    name: "print",
    action: "print-viewport",
    dropdown: true,
    icon: "mdi-printer"
  }
];

const _viewerViewportOptions = [
  {
    name: "show_metadata_overlay",
    group: "2D layout",
    value: true
  },
  {
    name: "show_viewport_overlay",
    group: "2D layout",
    value: true
  },
  {
    name: "show_scale_overlay",
    group: "2D layout",
    tool: { name: "ScaleOverlay" },
    type: "tool",
    value: false
  },
  {
    name: "show_orientation_markers",
    group: "2D layout",
    tool: { name: "OrientationMarkers" },
    type: "tool",
    value: false
  },
  {
    name: "editing_layout_show_vr",
    group: "Advanced layouts",
    value: false
  }
];

export const MPRToolsDictionary = Object.freeze({
  Crosshair: "crosshair",
  Pan: "pan",
  Wwwc: "level",
  Zoom: "zoom"
});

export const VRToolsDictionary = Object.freeze({
  Crosshair: "Rotation",
  Crop: "crop"
});

// Default tools by canvas types

const _defaultTools = {
  [_CanvasTypes.stack]: { name: "Wwwc" },
  [_CanvasTypes.mpr]: { name: "Crosshair" },
  [_CanvasTypes.vr]: { name: "Crosshair" }
};

// Disabled features by canvas types

const _disabledTools = {
  [_CanvasTypes.stack]: ["Crosshair", "Crop"],
  [_CanvasTypes.mpr]: [
    "Cinematic",
    "Rotate",
    "Crop",
    "WwwcRegion",
    "WwwcRemoveRegion",
    "StackScroll",
    ..._viewerMeasurementsTools.map(t => t.name),
    ..._viewerViewportActions.map(t => t.name),
    ..._viewerViewportOptions.map(t => t.name)
  ],
  [_CanvasTypes.vr]: [
    "Wwwc",
    "WwwcRegion",
    "WwwcRemoveRegion",
    "StackScroll",
    "Zoom",
    "Pan",
    "Rotate",
    "Cinematic",
    ..._viewerMeasurementsTools.map(t => t.name),
    ..._viewerViewportActions.map(t => t.name),
    ..._viewerViewportOptions.map(t => t.name)
  ]
};

export const pagingBackend = {
  active: false,
  size: 10
};

// Exports
export const CanvasTypes = _CanvasTypes;
export const dashboardGroups = ["study", "patient"];
export const dashboardTableHeadersPatients = _dashboardTableHeadersPatients;
export const dashboardTableHeadersStudies = _dashboardTableHeadersStudies;
export const dashboardTableHeadersSeries = _dashboardTableHeadersSeries;
export const defaultTools = _defaultTools;
export const demoUserCredentials = {
  email: "demo@dvisionlab.com",
  password: "Dv_demo01"
};
export const dicomImportTableHeaders = _dicomImportTableHeaders;
export const disabledTools = _disabledTools;
export const schemaToStackMap = _schemaToStackMap;
export const viewerLayouts = _layouts;
export const viewerLayoutsUI = _layoutsUI;
export const viewerInteractionsTools = _viewerInteractionsTools;
export const viewerMeasurementsTools = _viewerMeasurementsTools;
export const customerSpecificMeasurementTools = _customerSpecificMeasurementTools;
export const viewerViewportActions = _viewerViewportActions;
export const viewerViewportOptions = _viewerViewportOptions;
export const viewportStaticInfo = _viewportStaticInfo;
