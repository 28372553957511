import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import NavigationBar from "@/components/NavigationBar";

Vue.use(VueRouter);

// Dynamic components
const OpenViewerLink = () => import("@/components/OpenViewerLink");
const DashboardBar = () => import("@/views/DashboardBar");
const Dashboard = () => import("@/views/Dashboard");
const Viewer = () => import("@/views/Viewer");
const SharedViewerLoader = () => import("@/views/SharedViewerLoader");
const ViewerBar = () => import("@/views/ViewerBar");
const ViewerNav = () => import("@/views/ViewerNav");
const ExternalUpload = () => import("@/components/ExternalUpload");

// Routes
const routes = [
  {
    path: "*",
    redirect: "/exams"
  },
  {
    path: "/exams",
    name: "exams",
    meta: { auth: true, autoLogin: true },
    mainMenuLink: true,
    components: {
      default: Dashboard,
      bar: DashboardBar,
      sidebar: NavigationBar
    },
    icon: "mdi-view-list-outline"
  },
  {
    path: "/viewer/:externalStudyId?",
    components: {
      default: Viewer,
      bar: ViewerBar,
      nav: ViewerNav,
      sidebar: NavigationBar
    },
    meta: { autoLogin: true },
    name: "viewer",
    mainMenuLink: true,
    mainMenuLinkComponent: OpenViewerLink,
    children: [
      {
        path: "shared",
        name: "sharedId",
        meta: { autoLogin: false },
        mainMenuLink: false,
        component: SharedViewerLoader
      }
    ],
    icon: "mdi-image-filter-black-white"
  },
  {
    path: "/shared/:sharedId",
    name: "shared",
    meta: { autoLogin: false },
    mainMenuLink: false,
    mainMenuLinkComponent: OpenViewerLink,
    components: {
      default: SharedViewerLoader,
      bar: ViewerBar,
      nav: ViewerNav,
      sidebar: NavigationBar
    }
  },
  {
    path: "/upload/:uploadId",
    name: "upload",
    meta: { autoLogin: false },
    mainMenuLink: false,
    components: {
      default: ExternalUpload
    }
  },
  {
    path: "/report-bug",
    name: "report-bug",
    meta: { auth: false, autoLogin: false },
    components: {
      default: null
    },
    beforeEnter(to) {
      window.open(
        `${process.env.APP_CUSTOMER.serviceDeskUrl}/portal/2/group/2/create/11?customfield_10107=${to.params.buildId}`,
        "_blank"
      );
    }
  },
  {
    path: "/help",
    name: "help",
    meta: { auth: false, autoLogin: false },
    components: {
      default: null
    },
    beforeEnter() {
      window.open(
        `${process.env.APP_CUSTOMER.serviceDeskUrl}/portals`,
        "_blank"
      );
    }
  },
  {
    path: "/settings",
    name: "settings",
    meta: { auth: true, autoLogin: true },
    components: {
      default: null,
      sidebar: NavigationBar
    },
    children: [
      {
        path: "account",
        name: "account",
        components: {
          default: null
        }
      }
    ]
  },
  // Dynamically register customer-specific routes
  ...process.env.APP_CUSTOMER.routes.map(route => ({
    ...route,
    path: `/${process.env.APP_CUSTOMER_NAME}/${route.name}`,
    name: `${process.env.APP_CUSTOMER_NAME}-${route.name}`,
    meta: route.meta,
    components: {
      sidebar: NavigationBar,
      ...Object.entries(route.components || {}).reduce((acc, [key, value]) => {
        acc[key] = () =>
          import(`@/customers/${process.env.APP_CUSTOMER_NAME}/${value}`);
        return acc;
      }, {})
    }
  }))
];

// Redirect to viewer if external 3rd-party URL is available
if (process.env.APP_CUSTOMER.getExternal3rdPartyURL) {
  routes[0].redirect = "/viewer";
  routes.push(
    { path: "/", redirect: "/viewer" },
    { path: "/login", redirect: "/viewer" }
  );
}

const router = new VueRouter({
  // mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return goTo(0);
    }
  }
});

export default router;
